import React, { Component } from 'react';
import { connect } from 'react-redux';
import AppConstant from '../../constants/app';
import axios from 'axios';

// Import custom components
import AxiosResultHandler from '../common/AxiosResultHandler.component';
import DigiSeal from '../common/digiSeal.component.jsx';

// this component acts as a landing + redirect page for "token" URLs.

// The scenario: you want a user to register + sign, and you want to pay for it in full.
// You generate a "token" with a PAK, and this "token" can then be used to reg + id.
// The user gets this "token" as part of an URL that opens this page.
// This page checks what the "token" state is, and presents the appropriate next screen.

class TokenRedirectForm extends Component {
    constructor(props) {
        super(props);

		this.state = {
			commonError: null,
			commonErrorDetails: null,
			button: null,
			name: null,
			done: false,
		};
	}

	componentDidMount(){
		document.title = "Registrieren und Identifizieren für sign-me Fernsignatur - digiSeal Portal by secrypt GmbH"

	    let t = this.props.params.signerToken;
		this.props.token(t); // put in store
		this.getSignerStatus();
	}

	openPopup(url) {
		var x = window.screenX;
		var y = window.screenY;
		var w = window.innerWidth;
		var h = window.innerHeight;
		if (w > 600) {
			w -= 200;
			x += 100;
		}
		window.open(url, '_blank', 'left='+x+',top='+y+',width='+w+',height='+h+',menubar,toolbar,location,status,resizable,scrollbars,noopener,noreferrer');
	}

	getSignerStatus() {

		// reset existing errors
		this.setState({
			commonError:null,
			commonErrorDetails:null,
			button: null,
		});

		const buttonRetry = {
			text: 'Vorgang wiederholen',
			target: 'retry',
		};

	    let t = this.props.params.signerToken;
		var data = {
			signerToken: t,
		};
		axios({
			method: 'post',
			url: AppConstant.API_URL + 'get-signer-status',
			data: data,
			timeout: 5000,
			validateStatus: status => { return true; },
		})
		.then(res => {
			console.log(res);

			if (res.status == 200 && res.data && res.data.status == "ok")
			{
				if (res.data.data && res.data.data.signerStatus) {
					var ss = res.data.data.signerStatus;
					var name = res.data.data.signerName;
					this.props.name(name);
					this.setState({name:name});

					if (ss.signerExisting !== true) {
						this.setState({
							button: {
								default: true, 
								target: 'create',
								text: 'Account anlegen'
							},
						});
						return;
					}

					if (ss.signerEmailAddressNeedsConfirmation !== false) {
						// we cannot help here. The user must confirm the D-Trust email.
						this.setState({
							commonError:"Sie haben Ihre sign-me E-Mail-Adresse noch nicht bestätigt.",
							commonErrorDetails:"Bitte schauen Sie in Ihr E-Mail-Postfach, ob Sie eine E-Mail von D-Trust zur Verifikation Ihres sign-me Accounts erhalten haben. Klicken Sie auf den Link in dieser E-Mail.",
							button: buttonRetry,
						});
						return;
					}

					if (ss.signerPasswordNeedsReset !== false) {
						this.setState({
							commonError:"Ihr Passwort ist nicht mehr gültig. Bitte erneuern Sie Ihr Passwort auf https://www.sign-me.de/",
							commonErrorDetails:"Es gab 5 fehlerhafte Anmeldeversuche. Ihr sign-me Account wurde deshalb gesperrt.",
							button: { target: 'https://www.sign-me.de/', text: 'sign-me Webseite öffnen'},
						});
						return;
					}

					if (ss.signerPasswordNeedsChangeOfInitPassword !== false) {
						this.setState({
							commonError:"Sie haben Ihr sign-me Passwort noch nicht festgelegt. Bitte erstellen Sie Ihr Passwort auf https://www.sign-me.de/",
							button: { target: 'https://www.sign-me.de/', text: 'sign-me Webseite öffnen'},
						});
						return;
					}

					if (ss.activeIdentityVerficationProcessURL !== null) {
						// ok... we still have a valid validation process! Use that!
						this.setState({
							button: { default:true, target: 'identify', text: 'Identifizierung fortsetzen'},
						});
						return;
					}

					if (ss.signerNeedsIdentificationAndTokenCreation === true) {
						// ok, sign-me says we need to create a validation process.
						this.setState({
							button: { default:true, target: 'identify', text: 'Identifizierung starten'},
						});
						return;
					}

					// we are done!
					this.setState({
						commonError: null,
						done: true,
					});
					return;

				}

				this.setState({
					commonError:"Technischer Fehler: Die Antwort vom Server ist ungültig.",
					button: buttonRetry,
				});
				return;
			}

			var ret = AxiosResultHandler.ok(res);
			ret.button = buttonRetry;
			this.setState(ret);
		})
		.catch(error => {
			var ret = AxiosResultHandler.error(error);
			ret.button = buttonRetry;
			this.setState(ret);
		});

		return;
	}

	handleSubmitBind(arg) {
		return event => {
			let t;
			event.preventDefault();
			switch (arg) {
				case 'retry':
					this.getSignerStatus();
					break;

				case 'create':
				    t = this.props.params.signerToken;
					location.hash = "signerToken/createsignmeaccount/" + t;
					window.scroll(0,0);
					break;

				case 'identify':
				    t = this.props.params.signerToken;
					location.hash = "signerToken/startsignmeidentify/" + t;
					window.scroll(0,0);
					break;

				default:
					// regular URL. use popup
					this.openPopup(arg);
			}
		}
	}

	render() {
		return (
			<div id='landingpage' className="content">
				<div className="grey">
					<div className="container emphasis">
						<p>
							<strong>„sign-me“</strong> ist der Fernsignatur-Service der D-Trust GmbH (Ein Unternehmen der Bundesdruckerei),
							den Sie in Kombination mit der 
							Signatursoftware <a className="arrow" href="https://www.secrypt.de/produkte/digiseal-web/" target='_blank'><DigiSeal />web </a>
							&nbsp;der&nbsp;
							<a className="arrow" href="https://www.secrypt.de" target='_blank'>secrypt GmbH</a> nutzen können.
							Zur Klärung von etwaigen Fragen lesen Sie bitte unsere FAQ oder wenden sich an Ihren Anbieter dieses Links.
						</p>
					</div>
				</div>
				<div className="white">
					<div className="container center">
						<div className="spacer" />
						<div className='narrow'>
							<p style={{textAlign:'left'}}>
								Um Dokumente qualifiziert elektronisch per <strong>sign-me</strong>-Fernsignatur unterschreiben zu können,
								müssen Sie sich bei sign-me registrieren und identifizieren.
								<br/>
								Folgen Sie den Anweisungen: Registrieren Sie zuerst Ihren <strong>sign-me</strong> Account.
								Identifizieren Sie sich anschließend bei <strong>sign-me</strong>.
							</p>
						</div>
						<div className="spacer" />
						{this.state.done &&
							<div className='narrow' style={{textAlign:'left'}}>
								<h1>Fertig</h1>
								<p>
									Diese URL ist schon fertig Identifiziert.
									Sie können sich jetzt mit der E-Mail-Adresse <strong>{this.state.name}</strong> fernsignieren.
								</p>
							</div>
						}

						{this.state.commonError &&
							<div className="narrow">
								<ul className="narrow errorList">
									<li>Es gibt einen Fehler bei diesem Link.</li>
									<li>{this.state.commonError}</li>
									{this.state.commonErrorDetails &&
										<li className='small'>
											Folgende Details können Sie helfen das Problem zu lösen:
											<br/>
											{this.state.commonErrorDetails}
										</li>
									}
								</ul>
							</div>
						}

						{this.state.button &&
							<div className="buttoncontainer narrow">
								{!this.state.button.default && <div className="remainder" />}
								<div className={"button " + (this.state.button.default ? "yellow" : 'fixed')}>
									<button onClick={this.handleSubmitBind(this.state.button.target)}>{this.state.button.text}</button>
								</div>
							</div>
						}
					</div>
					<div className="spacer"></div>
					<div className="container">
						<p><a className="arrow light" href="https://www.secrypt.de/produkte/support/#signmefaqs" target='_blank'>FAQs (Häufige Fragen und Antworten)</a></p>
					</div>
				</div>
			</div>
		);
	}
}

// this is actually currently not used for the token (26.4.2022), since
// the user token is encoded in the hash token for the following web pages.
// it is used for the e-mail though, although java simply overrides it in case it is not given.
const mapDispatchToProps = {
	'token': (token) => ({
		'type': 'signerToken/token',
		'token': token,
	}),

	'name': (name) => ({
		'type': 'signerToken/name',
		'name': name,
	}),
};

export default connect (null, mapDispatchToProps)(TokenRedirectForm);
