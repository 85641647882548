import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import AppConstant from '../../constants/app';
import cookie from 'react-cookie';
import axios from 'axios';
import _ from 'lodash';
import * as authService from '../../services/authService';

import DigiSeal from '../common/digiSeal.component.jsx';
import * as flashMessage  from '../../actions/flashMessage';
import renderText from '../login/renderText';
import sha256 from "../../constants/sha256";

class LoginActivateForm extends Component {

    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
        // check for cookie set and forward user to dashboard if already logged in
        if (cookie.load(AppConstant.TOKEN)) {
            window.location.href = AppConstant.DASHBOARD_URL;
        }
    }

    componentDidMount(){
        let token = this.props.params.id;
        let uid = this.props.params.uid;
        if (uid)
            uid = decodeURIComponent(uid.replace(/\+/g, ' '));
        const password = document.getElementsByName("password");
        const togglePassword = document.querySelector('#togglePassword');

        togglePassword.addEventListener('click', function (e) {
            const type = password[0].getAttribute('type') === 'password' ? 'text' : 'password';
            password[0].setAttribute('type', type);
            this.classList.toggle('dash');
        });
        $('input[name="username"]').val(uid);

        if (token) {
            $('input[name="token"]').val(token);
        }
    }

    componentWillMount() {
        window.$('body').addClass('hold-transition login-page');
    }

    componentWillUnmount() {
        this.props.actions.removeFlashMessage();
    }

    handleSubmit() {

        $('body').find('.help-block').html('');
        $('body').find('.error').removeClass('error');

        if ($('.error').length == 0) {

            let token = $('input[name="token"]').val();
            let username = $('input[name="username"]').val();
            let password = $('input[name="password"]').val();
            let propss = {};
            propss.username = username;
            propss.password = password;
            var actionHandler = this.props.actions;

            axios.post(AppConstant.API_URL + 'account-activate', {token, username, password})
                .then(res => {
                    let ok = false;
                    //let message = 'Es tut uns leid, es ist leider bei der Aktivierung ein Fehler aufgetreten.'

					let message = 'Bei der Aktivierung Ihres digiSeal Kontos ist leider ein Fehler aufgetreten.'
							+ ' Bitte versuchen Sie es in ein paar Minuten noch einmal,'
							+ ' indem Sie den Aktivierungslink aus der E-Mail noch einmal betätigen.';

                    if (res.data.message_clear != undefined){
                        message = res.data.message_clear;
                    }

                    if (res.data.code == "ok") {
                        ok = true;
                    }

                    bootbox.alert({
                        message: message,
                        callback: function () {
                            if (ok) {
                                actionHandler.login(propss);
                            }
                            if (res.data.action == "create") {
                                window.location.href = AppConstant.ROOT_URL + 'create';
                            }
                        }
                    });

                    if (res.data.debugMessage != undefined || res.data.debugData != undefined)
                        console.error("An error occured:", res.data.debugMessage, res.data.debugData);
                });
        }
        return false;
    }

    render() {

        let message = this.props.message;
        console.log(this.props.params.uid);
        const {handleSubmit, submitting} = this.props;

		return (
			<div className="content">
				<div className="login-box">
					<div className="login-box-body">
						<h3><DigiSeal /> Konto Aktivierung</h3>
						<p className="login-box-msg">Bitte geben Sie zur Aktivierung Ihres <DigiSeal /> Kontos Ihr Passwort ein.</p>

						<form method="post" onSubmit={handleSubmit(this.handleSubmit)}>
							<div className="form-group has-feedback ">
								<input readOnly={true} name="username" className="form-control" placeholder="Benutzername" type="text" />
									<div className="help-block"></div>
							</div>

							<Field
								name="password"
								component={renderText}
								type="password"
								label="Passwort"
							>
								<i className="eye togglePassword" id="togglePassword"></i>
							</Field>

							<input type="hidden" name="token" />

							<div className="row">
								<div className="col-xs-8">
									<a href="#/resetpw/" className="arrow resetPw">Passwort vergessen?</a>
								</div>
								<div className="col-xs-4 button">
									<button type="submit" className="btn btn-primary btn-block btn-flat"
											disabled={submitting}>Aktivieren
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		);
	}
}


LoginActivateForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired
};

/**
 * Map the state to props.
 */
function mapStateToProps(state) {
    return {
        token: state.auth.token,
        isAuthenticated: state.auth.isAuthenticated,
        message: state.flash.message
    }
}

/**
 * Map the actions to props.
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(_.assign({}, authService, flashMessage), dispatch)
    }
}

const validateLogin = values => {
    const errors = {};
    if (!values.username) {
        errors.username = 'Bitte geben Sie Ihren Benutzernamen ein.'
    }

    if (!values.password) {
        errors.password = 'Bitte geben Sie Ihr Passwort ein.'
    }
    return errors
};

/**
 * Connect the component to the Redux store.
 */
export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'LoginActivateForm', // ←A Unique identifier for this form
    validate: validateLogin,  // ←Callback function for client-side validation
})(LoginActivateForm))
