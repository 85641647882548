 
import React, { Component } from 'react';
import { connect } from 'react-redux';
import AppConstant from '../../constants/app';
import axios from 'axios';

// Import custom components
import AxiosResultHandler from '../common/AxiosResultHandler.component';

function zeroFill(value, n) {
	return ('0000' + value).slice(-n);
}

function getClassName(basename, props) {
	return basename
		+ (props.error ? " error" : "")
		+ (props.narrow ? " narrow" : "")
		+ (props.fixed ? " fixed" : "")
		+ (props.remainder ? " remainder" : "");
}

var FieldText = (props) => {
	var name = getClassName("textinput", props);
	var value = props.bind.value;
	if (props.date && value) {
		// yyyyMMdd -> dd.mm.yyyy
		value = value.slice(6) + '.' + value.slice(4,6) + '.' + value.slice(0,4);
	}
	return (
		<div className={name} id={props.id}>
			<label>{props.name}&nbsp;{props.required && <span className="required" title="erforderlich">*</span>}</label>
			<input
				type={props.password ? "password" : "text"}
				name={"autocomplete-"+props.bind.name}
				readOnly={props.readOnly}
				autoComplete={props.autocomplete}
				value={value}
				onChange={e => props.bind.setter(e.target.value)}
			></input>
			{props.bind.error && <div className="error">{props.bind.error}</div>}
		</div>
	);
}

var FieldCheckbox = (props) => {
	return (
		<div className={props.error ? "checkinput error" : "checkinput"}>
			<div className="view">
				<input
					type="checkbox"
					value={props.bind.value}
					onChange={e => {
						props.bind.setter(e.target.checked)}}
				/>
				<div>
					<svg viewBox="0 0 20 20">
						<path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"></path>
					</svg>
					<label>
						<span>
							{props.children}
							&nbsp;
							{props.required && <span className="required" title="erforderlich">*</span>}
						</span>
					</label>
				</div>
			</div>
			{props.bind.error && <div className="error">{props.bind.error}</div>}
		</div>
	);
}

var FieldCountry = (props) => {
	return (
		<div className={props.error ? "countryoption selectinput error" : "countryoption selectinput"} >
			<label>{props.name}&nbsp;{props.required && <span className="required" title="erforderlich">*</span>}</label>
			<select value={props.bind.value} onChange={e => props.bind.setter(e.target.value)}>
				<option value="">-</option>
				<option value="D">Deutschland</option>
				{props.countries.map( (value) => {
					return value.code != "D" && <option key={value.code} value={value.code}>{value.name}</option>;
				})}
			</select>
			{props.bind.error && <div className="error">{props.bind.error}</div>}
		</div>
	);
}

var FieldCountryCallingCode = (props) => {
	var name = getClassName("selectinput", props);
	return (
		<div className={name}>
			<label>{props.name}&nbsp;{props.required && <span className="required" title="erforderlich">*</span>}</label>
			<select value={props.bind.value} onChange={e => props.bind.setter(e.target.value)}>
				<option value="+49">+49</option>
				<option value="+1">+1</option>
				<option value="+27">+27</option>
				<option value="+30">+30</option>
				<option value="+31">+31</option>
				<option value="+32">+32</option>
				<option value="+33">+33</option>
				<option value="+34">+34</option>
				<option value="+351">+351</option>
				<option value="+352">+352</option>
				<option value="+353">+353</option>
				<option value="+354">+354</option>
				<option value="+356">+356</option>
				<option value="+357">+357</option>
				<option value="+358">+358</option>
				<option value="+359">+359</option>
				<option value="+36">+36</option>
				<option value="+370">+370</option>
				<option value="+371">+371</option>
				<option value="+372">+372</option>
				<option value="+385">+385</option>
				<option value="+386">+386</option>
				<option value="+39">+39</option>
				<option value="+40">+40</option>
				<option value="+41">+41</option>
				<option value="+420">+420</option>
				<option value="+421">+421</option>
				<option value="+423">+423</option>
				<option value="+43">+43</option>
				<option value="+44">+44</option>
				<option value="+45">+45</option>
				<option value="+46">+46</option>
				<option value="+47">+47</option>
				<option value="+48">+48</option>
				<option value="+52">+52</option>
				<option value="+54">+54</option>
				<option value="+55">+55</option>
				<option value="+61">+61</option>
				<option value="+62">+62</option>
				<option value="+65">+65</option>
				<option value="+7">+7</option>
				<option value="+81">+81</option>
				<option value="+82">+82</option>
				<option value="+86">+86</option>
				<option value="+90">+90</option>
				<option value="+91">+91</option>
				<option value="+966">+966</option>
				<option value="+971">+971</option>
				<option value="+974">+974</option>
			</select>
			{props.bind.error && <div className="error">{props.bind.error}</div>}
		</div>
	);
}

class CreateSignMeAccountForm extends Component {

	constructor(props) {
        super(props);

		this.handleSubmitBind = this.handleSubmit.bind(this);
		this.bindValueBind = this.bindValue.bind(this);

		this.state = {
			'email': '',
			'title': '',
			'vorname': '',
			'familyname': '',
			'password': '',
			'passwordrepeat': '',
			'mobilephonenumber': '',
			'title': '',
			'birthdate': '',
			'birthplace': '',
			'street': '',
			'postalcode': '',
			'city': '',
			'countrycallingcode': '+49',
			'nationality': '',
			'birthcountry': '',
			'country': '',
			'smspin': '',
			'smspinrepeat': '',
			'legalcheck': false,

			jsonPerson: null,

			countries: [],
			errors: {},
			commonError: null,
			commonErrorDetails: null,
			submitting: false,
			optionalDataVisible: false,
			emailFixed: false,
			showPinFields: false,
		};
	}

	componentDidMount() {
		document.title = "Registrierung für sign-me - secrypt GmbH"

		$.extend($.fn.pickadate.defaults, {
			monthsFull: ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
			weekdaysShort: ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'],
			today: 'Heute',
			clear: false,
			close: 'Schließen',
		});

		var setBirthdate = (value) => {
			this.setState({'birthdate': value});
		};

		$('#signme-birthdate input').pickadate({
			labelMonthNext: 'Zum nächsten Monat',
			selectMonths: true,
			selectYears: 110,
			max: true,
			hiddenName: true,
			onSet: function (x) {
				if (x.select) {
					var date = new Date(x.select);
					var y = zeroFill(date.getFullYear(),4);
					var m = zeroFill(date.getMonth()+1,2);
					var d = zeroFill(date.getDate(),2);
					setBirthdate(y+m+d); // yyyyMMdd
				}
			},
		});


		// if we have a token, we use the token to get the email
	    const t = this.props.params.signerToken;
		if (t) {
			// we do axios always, that makes the code easier and more stable
			// if (this.props.signerToken && this.props.signerToken.name) {
			// 	// we already have the email via the store
			// 	this.setState({email:this.props.signerToken.name});
			// }

			axios({
				method: 'post',
				url: AppConstant.API_URL + 'get-signer-status',
				data: { signerToken: t },
			})
			.then(res => {
				console.log ('get e-mail/person for this token');
				if (res.status == 200 && res.data && res.data.status == "ok") {
					if (res.data.data) {
						const email = res.data.data.signerName;
						const jsonPerson = res.data.data.jsonPerson;

						if (email) {
							this.setState({
								email:email,
								emailFixed:true,
							});
						}
						if (jsonPerson) {
							var json = JSON.parse(jsonPerson);
							if (!json) {
								console.log ('cannot parse suggested person data from server');
								console.log (jsonPerson);
								return;
							}

							this.setState({
								jsonPerson:json,
							});
						}
					}
				}
			});
		}

		// get countries list
		axios.post(AppConstant.API_URL + 'get-country-list')
			.then(res => {
				this.setState({countries: res.data.countries});
			});
	}

	componentDidUpdate(prevProps, prevState) {
		var pOld = prevState.jsonPerson;
		var cOld = prevState.countries.length > 0;
		var pNew = this.state.jsonPerson;
		var cNew = this.state.countries.length > 0;

		let mobileOld = prevState['mobilephonenumber'];
		let mobileNew = this.state['mobilephonenumber'];

		if (!pOld && pNew)
			this.handleJsonPerson (this.state.jsonPerson);

		if (pNew && cNew && (!pOld || !cOld))
			this.handleJsonPersonCountries (this.state.jsonPerson, this.state.countries);

		if (mobileOld && mobileNew)
			this.handlePhoneNumberChange(mobileOld, mobileNew);
	}

	bindValue (valueName) {
		return {
			'name': valueName,
			'value': this.state[valueName],
			'error': this.state.errors[valueName],
			'setter': value => {
				var s = {};
				s[valueName] = value;
				this.setState(s);
			}
		};
	}

	handleJsonPerson (json) {
		var state = {};
		for (let [key, value] of Object.entries({
			academicTitle: 'title',
			familyNames: 'familyname',
			givenNames: 'vorname',
			placeOfBirth: 'birthplace',
			streetAndNumber: 'street',
			zipCode: 'postalcode',
			city: 'city',
			mobileNumber: 'mobilephonenumber',
			mobileNumberCountryCode: 'countrycallingcode',
		})) {
			if (json[key]) {
				state[value] = json[key];
			}
		}

		if (json['dateOfBirth']) {
			state['birthdate'] = json['dateOfBirth'];
		}

		if (json['smspin']) {
			state['smspin'] = json['smspin'];
		}

		this.setState(state);
	}

	handleJsonPersonCountries (json, countries) {
		var state = {};
		for (let [key, value] of Object.entries({
			nationality: 'nationality',
			countryOfBirth: 'birthcountry',
			country: 'country',
		})) {
			if (json[key]) {
				var validCountry = countries.some (e => {
					return e.code == json[key];
				});
				if (validCountry)
					state[value] = json[key];
			}
		}

		this.setState(state);
	}

	handlePhoneNumberChange (oldNumber, newNumber) {
		if (oldNumber === newNumber)
			return;
		this.setState({
			showPinFields: false,
			'smspin': '',
			'smspinrepeat': ''
		});
	}

	isEmail(email) {
        var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        return regex.test(email);
    }

	// normalize the base number, so without country calling code.
	// remove spaces, dashes.
	// returns null if the number contains invalid chars, or starts with a 0.
	normalizePhoneNumber(number) {
		var n = number.replace(/[\s-]+/g, '');
		if (n.length == 0 || n[0] == 0)
			return null;
		if (n.match(/[^\d]/) !== null)
			return null;
		return n;
    }

	// returns null if the password is without fault
	// requirements: at least 12 + lower case + upper case + number + special char + no repeats
	getPasswordError(password) {
		var r = [];
		if (password.length < 12)                                      r.push("Passwort zu kurz.");
		if (password.match(/[A-Z]/) == null)		                  r.push("Großbuchstabe fehlt.");
		if (password.match(/[a-z]/) == null)		                  r.push("Kleinbuchstabe fehlt.");
		if (password.match(/[0-9]/) == null)	                      r.push("Nummer fehlt.");
		if (password.match(/[\!\+\-\_\*\$\?\:\#\&\@\;\~\%]/) == null) r.push("Sonderzeichen fehlt.");
		var run = 1;
		for (var i = 1; i < password.length; i++) {
			if (password[i] == password[i-1])
				run++;
			else if (run < 4)
				run = 1;
		}
		if (run >= 4)
			r.push("Zuviel gleiche Zeichen hintereinander.")

		if (r.length == 0)
			return null;
		else
			return r.join(" ");
	}

	handleSubmit(event) {
		event.preventDefault();

		var errors = {};

		//this.setState({submitting:true});

		// reset previous network errors (the regular errors are overwritten below)
		this.setState({
			commonError:null,
			commonErrorDetails:null,
		});

		// extract elements from form
		var elements = {};
		for (const e of [
			'vorname',
			'familyname',
			'email',
			'password',
			'passwordrepeat',
			'mobilephonenumber',
			'title',
			'birthdate',
			'birthplace',
			'street',
			'postalcode',
			'city',
			'smspin',
			'smspinrepeat',
		]) {
			elements[e] = (this.state[e] || '').trim();
		}
		for (const e of [
			'countrycallingcode',
			'nationality',
			'birthcountry',
			'country',
		]) {
			elements[e] = (this.state[e] || '').trim();
			if (elements[e] === '-') /* this is as good as empty */
				elements[e] = '';
		}
		
		elements['legalcheck'] = this.state['legalcheck'];

		// fields that are required
		for (const e of [
			{ id:'vorname' },
			{ id:'familyname' },
			{ id:'birthdate' },
			{ id:'birthplace' },
			{ id:'birthcountry' },
			{ id:'nationality' },
			{ id:'street' },
			{ id:'postalcode' },
			{ id:'city' },
			{ id:'country' },
			{ id:'email' },
			{ id:'password' },
			{ id:'passwordrepeat' },
			{ id:'countrycallingcode' },
			{ id:'mobilephonenumber' },
		]) {
			var v = elements[e.id];
			if (!v) {
				errors[e.id] = "Dieses Feld darf nicht leer sein.";
			}
		}
		if (this.state['showPinFields']) {
			if (!elements['smspin'])
				errors['smspin'] = "Dieses Feld darf nicht leer sein.";
			else if (!this.normalizePhoneNumber(elements['smspin']))
				errors['smspin'] = "Die PIN darf nur Zahlen enthalten.";
			else if (elements['smspin'].length !== 4)
				errors['smspin'] = "Die PIN muss genau 4 Stellen lang sein.";

			if (elements['smspin'] !== elements['smspinrepeat'])
				errors.smspinrepeat = "Die SMS-Pin stimmt nicht überein.";
		}

		// fields that are 180-Strings
		for (const e of [
			{ id:'vorname' },
			{ id:'familyname' },
			{ id:'title' },
		]) {
			var v = elements[e.id];
			if (v.length > 180) {
				errors[e.id] = "Der Text ist zu lang (max. 180 Zeichen erlaubt).";
			}
		}

		if (!elements['legalcheck']) {
			errors['legalcheck'] = "Bitte stimmen Sie der Datenschutzerklärung der secrypt GmbH zu.";
		}

		if (!this.isEmail(elements['email'])) {
			errors.email = "Bitte geben Sie eine gültige E-Mail-Adresse ein.";
		}

		var nr = this.normalizePhoneNumber(elements['mobilephonenumber']);
		if (nr === null) {
			errors.mobilephonenumber = "Bitte geben Sie eine gültige Mobilfunknummer ein.";
		}
		elements['mobilephonenumber'] = elements['countrycallingcode'] + " " + nr;
	
		if (elements['password'] !== elements['passwordrepeat']) {
			errors.passwordrepeat = "Die Passwörter stimmen nicht überein.";
		}

		var pw = this.getPasswordError(elements['password']);
		if (pw !== null)
			errors.password = pw;

		// if a birthdate is provided, the user should be at least 18 years of age
		if (elements['birthdate']) {
			var str = elements['birthdate'];
			var y = str.substr(0,4);
			var m = str.substr(4,2);
			var d = str.substr(6,2);
			var date = new Date(y,m-1,d);

			var msDay = 60*60*24*1000;
			if (Date.now() - date < msDay * 365.25 * 18) // el cheapo
				errors.birthdate = "Für das Anlegen eines Accounts müssen Sie mindestens 18 Jahre alt sein.";
		}

		// birthplace must constitute of place & country
		let birthplaceComplete = 
			elements['birthplace'].length &&
			elements['birthcountry'].length;
		let birthplacePartial=
			elements['birthplace'].length ||
			elements['birthcountry'].length;
		if (birthplacePartial && !birthplaceComplete) {
			for (const e of [
				'birthplace',
				'birthcountry'
			]) {
				var v = elements[e];
				if (!v) {
					errors[e] = "Bitte füllen Sie Ihre Geburtsdaten komplett aus.";
				}
			}
		}

	
		// the address must be complete or empty
		let addressComplete =
			elements['street'].length &&
			elements['city'].length &&
			elements['country'].length &&
			elements['postalcode'].length;
		let addressPartial =
			elements['street'].length ||
			elements['city'].length ||
			elements['country'].length ||
			elements['postalcode'].length;
		if (addressPartial && !addressComplete) {
			for (const e of [
				'street',
				'city',
				'country',
				'postalcode'
			]) {
				var v = elements[e];
				if (!v) {
					console.log("el"+e+v);
					errors[e] = "Bitte füllen Sie Ihre Adresse komplett aus.";
				}
			}
		}

		this.setState({errors:errors});

		var count = Object.keys(errors).length;
		if (count > 0) {
			this.setState({commonError:"Bitte prüfen Sie die Eingabefelder noch einmal."});
			return;
		}

		// all necessary elements have been entered.
		// call sign-me!

		let formData = {};

		formData.passwd =            elements['password'];
		formData.emailAddress =      elements['email'];
		formData.mobilePhoneNumber = elements['mobilephonenumber'];
		formData.smsPin =            elements['smspin'] || undefined;
		formData.givenNames =        elements['vorname']
		formData.familyNames =       elements['familyname']
		formData.academicTitle =     elements['title'] || undefined;
		formData.dateOfBirth =       elements['birthdate'] || undefined;
		formData.nationality =       elements['nationality'] || undefined;
		formData.indoctrination =    true;

		if (elements['birthplace']) {
			formData.placeOfBirth = {
				city:    elements['birthplace'],
				country: elements['birthcountry']
			};
		}

		if (elements['street']) {
			formData.placeOfResidence = {
				street:  elements['street'],
				city:    elements['city'],
				country: elements['country'],
				zipCode: elements['postalcode']
			};
		}

		// dit gaan we toch niet toestaan?
		// 'allowExtendedEmailAddressUse'
		// 'publishRequest'

		// dit doen we expliciet niet, omdat sign-me het ook niet in zijn interface heeft
		// formData.artisticName

		var data = {
			'person': formData
		}
	    const t = this.props.params.signerToken;
		if (t)
			data['signerToken'] = t;

		console.log("posting with ");
		console.log(data);

		axios({
			method: 'post',
			url: AppConstant.API_URL + 'create-signme-account',
			data: data,
			timeout: 10000, // ms - since this goes to sign-me, the timeout should be a bit longer than with the landing page.
			validateStatus: status => { return true; },
		})
		.then(res => {
			if (res.status == 200 && res.data && res.data.status == "ok")
			{
				// yay! success
			    const t = this.props.params.signerToken;
				window.location.hash = t ? ("signerToken/verifyemail/" + t) : "verifyemail";
				window.scroll(0,0);
				return;
			}

			var ret = AxiosResultHandler.ok (res);
			this.setState(ret);
		})
		.catch(error => {
			var ret = AxiosResultHandler.error (error);
			this.setState(ret);
		});
	}

	optionalDataVisibilityBind()
	{
		return () => {
			this.setState({optionalDataVisible:!this.state.optionalDataVisible});
		}
	}

	historyBack()
	{
		window.history.back();
	}

	render() {
		return (
			<div id="createsignmeaccount" className="content">
				<form method="post" onSubmit={this.handleSubmitBind}>
					<div className="white">
						<div className="container">
							<h1>Registrierung für sign-me</h1>
							<p>
								Geben Sie nachfolgend Ihre Daten, genau so wie sie in Ihrem Ausweisdokument stehen, ein,
								z.B. alle Vornamen.
								Diese Daten sind notwendig, damit Sie mit sign-me qualifizierte Signaturen erzeugen können.
							</p>
						</div>
					</div>
					<div className="grey">
						<div className="container">
							<div>
								<p className="explanation">
									<span className="required" title="erforderlich">*</span>&nbsp;Pflichtfelder
								</p>
							</div>
							<div className="card">
								<h2>Ihre Daten laut Ausweis</h2>
								<div className="itemcontainer">
									<FieldText name="Akademischer Titel"           autocomplete='honorific-prefix' bind={this.bindValueBind('title')}        />
									<FieldText narrow name="Vorname(n)"   required autocomplete='given-name'       bind={this.bindValueBind('vorname')}      />
									<FieldText narrow name="Nachname"     required autocomplete='family-name'      bind={this.bindValueBind('familyname')}   />
									<FieldText narrow name="Geburtsdatum" required date                            bind={this.bindValueBind('birthdate')}    id='signme-birthdate' />
									<FieldText narrow name="Geburtsort"   required                                 bind={this.bindValueBind('birthplace')}   />
									<FieldCountry     name="Geburtsland"  required                                 bind={this.bindValueBind('birthcountry')} countries={this.state.countries} />
									<FieldCountry     name="Nationalität" required                                 bind={this.bindValueBind('nationality')}  countries={this.state.countries} />
								</div>
							</div>
							{/* <div className="card">
								<h2 className={this.state.optionalDataVisible ? "folding rotate" : "folding"} onClick={this.optionalDataVisibilityBind()}>
									Optionale Angaben
								</h2>
							</div> */}
							<div className="card">
								<h2>Adresse</h2>
								<div className="itemcontainer">
									<FieldText name="Straße und Hausnummer" required autocomplete='street-address' bind={this.bindValueBind('street')}     />
									<FieldText narrow name="Postleitzahl"   required autocomplete='postal-code'    bind={this.bindValueBind('postalcode')} />
									<FieldText narrow name="Wohnort"        required autocomplete='address-level2' bind={this.bindValueBind('city')}       />
									<FieldCountry     name="Land"           required autocomplete='country'        bind={this.bindValueBind('country')}    countries={this.state.countries} />
								</div>
							</div>

							<div className="card">
								<h2>Ihre Anmeldedaten für sign-me</h2>
								<div className="itemcontainer">
									<FieldText 
										name="E-Mail-Adresse (sign-me Benutzername)"
										required
										readOnly={this.state.emailFixed}
										bind={this.bindValueBind('email')}
									/>
									<FieldText narrow name="Passwort wählen"      password required bind={this.bindValueBind('password')}       />
									<FieldText narrow name="Passwort wiederholen" password required bind={this.bindValueBind('passwordrepeat')} />
									<div>
										<p className="explanation">
											Ihr Passwort muss mindestens 12 Zeichen lang sein.
											Das Passwort muss mindestens einen <strong>Großbuchstaben</strong>,
											einen <strong>Kleinbuchstaben</strong>,
											eine <strong>Zahl</strong>, 
											und eines der <strong>Sonderzeichen</strong> !+-_*$?:#&@;~%
											enthalten.
											Das Passwort darf keine Ketten von gleichen Buchstaben enthalten.
										</p>
									</div>									
									<FieldCountryCallingCode fixed name="Ländervorwahl" required bind={this.bindValueBind('countrycallingcode')} />
									<FieldText remainder name="Mobilfunknummer (ohne führende Null)" required autocomplete='tel-national' bind={this.bindValueBind('mobilephonenumber')} />
									{this.state.showPinFields &&
										<div className="sms-pin">
											<p><b>SMS-PIN für Festnetznummer</b><br />
											Sie haben eine Festnetznummer angegeben. Um sign-me mit einer Festnetznummer nutzen zu können, müssen Sie Ihre <b>4-stellige</b> SMS-PIN festlegen. 
											Merken Sie sich Ihre SMS-PIN. Die SMS-PIN müssen Sie später bei der Erstellung Ihrer sign-me Fernsignaturen am Telefon eingeben.
											</p>
											<FieldText narrow name="SMS-PIN" password required bind={this.bindValueBind('smspin')} />
											<FieldText narrow name="SMS-PIN (Wdh.)" password required bind={this.bindValueBind('smspinrepeat')} />
										</div>
									}
									<FieldCheckbox required bind={this.bindValueBind('legalcheck')}>
										Hiermit stimme ich der <a href="https://www.secrypt.de/datenschutzerklaerung/" target="_blank">Datenschutzerklärung</a> der secrypt GmbH zu.
									</FieldCheckbox>
								</div>
							</div>

							{this.state.commonError &&
								<div>
									<div className="spacer"></div>
									<ul className="errorList">
										<li>Account konnte nicht angelegt werden.</li>
										<li>{this.state.commonError}</li>
										{this.state.commonErrorDetails && <li className="small">{this.state.commonErrorDetails}</li>}
									</ul>
									<div className="spacer"></div>
								</div>
							}

							<div className="buttoncontainer">
								<div className="button fixed">
									<button type="button" onClick={this.historyBack}>Abbrechen</button>
								</div>
								<div className="remainder">
								</div>
								<div className="button yellow fixed">
									<button type="submit" disabled={this.state.submitting}>Weiter</button>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		);
	}
}

const mapStateToProps = state => {
	const st = state.signerToken;
	if (!st)
		return {};

	return {
		signerToken: {
			token: st.token,
			name: st.name,
		}
	};
};

export default connect(mapStateToProps)(CreateSignMeAccountForm);
