import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import AppConstant from '../../constants/app';
import cookie from 'react-cookie';
import axios from 'axios';
import _ from 'lodash';
import DigiSeal from '../common/digiSeal.component.jsx';

import * as authService from '../../services/authService';
import * as flashMessage  from '../../actions/flashMessage';

import renderText from '../resetpw/renderText';

class CreateAccountForm extends Component {

    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);

        if (cookie.load(AppConstant.TOKEN)) {
            window.location.href = AppConstant.ACCOUNT_ROOT_URL + "digiseal-points";
        }
    }

    componentWillMount() {
        window.$('body').addClass('hold-transition login-page');
    }

    componentWillUnmount() {
        this.props.actions.removeFlashMessage();
    }

    componentDidMount(){
        setTimeout(function() {
            const togglePassword= document.querySelector('#togglePassword');
            const togglePasswordConfirm = document.querySelector('#togglePasswordConfirm');
            const password = document.getElementsByName("password");
            const passwordConfirm = document.getElementsByName("passwordrepeat");

            togglePassword.addEventListener('click', function (e) {
                const type = password[0].getAttribute('type') === 'password' ? 'text' : 'password';
                password[0].setAttribute('type', type);
                this.classList.toggle('dash');
            });
            togglePasswordConfirm.addEventListener('click', function (e) {
                const type = passwordConfirm[0].getAttribute('type') === 'password' ? 'text' : 'password';
                passwordConfirm[0].setAttribute('type', type);
                this.classList.toggle('dash');
            });
        }, 1000);

        document.title = "Konto erstellen – digiSeal Portal by secrypt GmbH"
    }

    isEmail(email) {
        var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        return regex.test(email);
    }

    handleSubmit(e) {

        let email = $('input[name="email"]');
        let emailRepeat = $('input[name="emailrepeat"]');
        let username = $('input[name="username"]');
        let password = $('input[name="password"]');
        let passwordRepeat = $('input[name="passwordrepeat"]');
        let conditions = $('input[name="conditionscheck"]');
        let contingentkey = $('input[name="contingentkey"]');

        $('body').find('.help-block').html('');
        $('body').find('.error').removeClass('error');

        if (username.val() == '') {
            username.addClass('error').parent().find('.help-block').addClass('error')
				.html('Geben Sie Ihren gewünschten Benutzernamen ein.');
        }

        if (password.val() == '') {
            password.addClass('error').parent().find('.help-block').addClass('error')
				.html('Geben Sie Ihr gewünschtes Passwort ein.');
        }
        if (passwordRepeat.val() == '') {
            passwordRepeat.addClass('error').parent().find('.help-block').addClass('error')
				.html('Geben Sie das Passwort nochmals ein.');
        }

        if (email.val() == '') {
            email.addClass('error').parent().find('.help-block').addClass('error')
				.html('Geben Sie Ihre E-Mail-Adresse ein.');
        }
        else {
            if (!this.isEmail(email.val())) {
                email.addClass('error').parent().find('.help-block').addClass('error')
					.html('Bitte prüfen Sie das E-Mail-Format.');
            }
            else if (emailRepeat.val() == '' ) {
                emailRepeat.addClass('error').parent().find('.help-block').addClass('error')
					.html('Geben Sie die E-Mail-Adresse nochmal ein.');
            }
            else if (emailRepeat.val() != '' && (emailRepeat.val() !== email.val())) {
                emailRepeat.addClass('error').parent().find('.help-block').addClass('error')
					.html('Die E-Mail-Adressen stimmen nicht überein.');
            }
        }

        if (contingentkey.val() == '') {
            contingentkey.addClass('error').parent().find('.help-block').addClass('error')
				.html('Geben Sie Ihren Punkte-Couponcode ein.');
        }

        if (passwordRepeat.val() != '' && (password.val() !== passwordRepeat.val())) {
            passwordRepeat.addClass('error').parent().find('.help-block').addClass('error')
				.html('Die Passwörter stimmen nicht überein.');
        }

        if (!conditions.is(':checked')) {
            conditions.addClass('error').parent().find('.help-block').addClass('error')
				.html('Bitte stimmen Sie den Bedingungen zu.');
        }

        if ($('.error').length == 0) {

            var formData = {};
            formData.emailValue = $('input[name="email"]').val();
            formData.usernameValue = $('input[name="username"]').val();
            formData.passwordValue = $('input[name="password"]').val();
            formData.contingentkeyValue = $('input[name="contingentkey"]').val();

            axios.post(AppConstant.API_URL + 'account-create', {formData})
                .then(res => {
                    let ok = false;
                    let message = '';
                    if (res.data.code == "ok") {
                        ok = true;
                        message = 'Vielen Dank für Ihre Registrierung. Sie werden in den nächsten Minuten eine E-Mail mit weiteren Instruktionen zur Aktivierung Ihres digiSeal<span className="trademark">®</span> Kontos erhalten.';
                    } else {
                        message = 'Bei der Registrierung ist ein Fehler aufgetreten. Bitte prüfen Sie, ob Ihr Punkte-Couponcode korrekt ist.';
                    }
                    if (res.data.message_clear != undefined) {
                        message = res.data.message_clear;
                    }

                    bootbox.alert({
                        message: message,
                        callback: function () {
                            if (ok) {
                                window.location.href = AppConstant.ROOT_URL + 'login';
                            }
                        }
                    });
                });
            
        }
        return false;
    }

	// openDatenschutzerklaerung (e) {
	// 	e.preventDefault ();
	// 	window.open('/#/datenschutzerklaerung/', 'newwindow', 'location,resizable,scrollbar,width=900,height=700');
	// 	return false;
	// }

    render() {

		const {handleSubmit, submitting} = this.props;

		return (
			<div className="login-box">
				<div className="login-box-body">
					<h3><DigiSeal /> Konto erstellen</h3>
					<p className="login-box-msg">
						Im <DigiSeal /> Konto können Sie Ihre <DigiSeal /> Punkte verwalten und
						überwachen. <DigiSeal /> Punkte dienen der Abrechnung der sign-me
						Fernsignatur in Kombination mit der	Signatursoftware <DigiSeal /> office (pro).
						<br/>
					</p>
					<p className="login-box-msg">
						<a className="arrow" href="https://www.secrypt.de/loesungen/fernsignatur-handy-signatur/" target="_blank">
						Hier informieren: Wie funktionieren <DigiSeal /> Punkte?</a>
					</p>
					<p className="login-box-msg">
						Zur Kontoerstellung benötigen Sie einen Punkte-Coupon:&nbsp;
						<a className="arrow" href="https://shop.secrypt.de/produkt/digiseal-punkte/" target="_blank">Online-Shop</a>
					</p>
					<p className="login-box-msg">
						Sie haben bereits ein <DigiSeal /> Konto? <a className="arrow" href="#/login">Anmelden</a>
					</p>

					<form method="post" id="registerform" name="registerform"
							onSubmit={handleSubmit(this.handleSubmit.bind(this))}>
						<Field
							name="username"
							component={renderText}
							type="text"
							label="Benutzername"
						>
						</Field>
						<Field
							name="password"
							component={renderText}
							type="password"
							label="Passwort"
						>
						<i className="eye togglePassword" id="togglePassword"></i>
						</Field>

						<Field
							name="passwordrepeat"
							component={renderText}
							type="password"
							label="Passwort wiederholen"
						>
						<i className="eye togglePasswordConfirm" id="togglePasswordConfirm"></i>

						</Field>

						<Field
							name="email"
							component={renderText}
							type="text"
							label="E-Mail"
						>
						</Field>
						<Field
							name="emailrepeat"
							component={renderText}
							type="text"
							label="E-Mail wiederholen"
						>
						</Field>
						<Field
							name="contingentkey"
							component={renderText}
							type="text"
							label="Punkte-Couponcode (CPN-...)"
						>
						</Field>

						<div className="conditions">
							<input type="checkbox" name="conditionscheck" value="1" />
							Ich stimme den <a className="arrow" href="https://www.secrypt.de/download-file/agb-secrypt-gmbh/" target="_blank">AGB/Nutzungsbedingungen</a> 						   zu und ich habe die
							<a className="arrow" href="https://www.secrypt.de/datenschutzerklaerung/" target="_blank">
								Datenschutzerklärung
							</a> zur Kenntnis genommen.
							<span className="help-block"></span>
						</div>

						<div className="row margin-top-20px">
							<div className="col-xs-4" />
							<div className="col-xs-8 button">
								<button type="submit" className="btn btn-primary btn-block btn-flat" disabled={submitting}><DigiSeal /> Konto erstellen
								</button>
							</div>

							<div className="col-xs-12">
								<div className="errormessage">
								</div>
							</div>

						</div>
					</form>
				</div>
			</div>
		);
	}
}

CreateAccountForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
    return {
        token: state.auth.token,
        isAuthenticated: state.auth.isAuthenticated,
        message: state.flash.message
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(_.assign({}, authService, flashMessage), dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'CreateAccountForm',
})(CreateAccountForm))
