

var AxiosResultHandler = (function(){

	var ApiErrorEnum = {
		InvalidContent:       101,
		ParsingError:         102,
		BdrParsingError:      103,
		BdrRejected:          104,
		UsernameAlreadyTaken: 105,
		UsernameUnknown:      106,
		BdrPasswordError:     108,
		InvalidPAK:           109,
		BdrSmsPinMissing:     110,
		BdrSmsPinNotAllowed:  111,
	
		TokenUnknown:         301,
		TokenInvalid:         302,

		PointsUnavailable:    402,
	};

	var handleOk = function(axiosResult) {

		var res = axiosResult;
		var ret = {
			commonError: " ",
			commonErrorDetails: undefined,
			showPinFields: false,
		};

		if (!res.data)
			return ret;

		if (typeof res.data == 'string') {
			ret.commonError = "Technischer Fehler: Die PHP-Antwort kann nicht dekodiert werden.";
			ret.commonErrorDetails = res.data;
			return ret;
		}

		if (res.data.details)
			ret.commonErrorDetails = res.data.details;

		switch (res.data.errorCode - 0) {
			case ApiErrorEnum.ParsingError:
				ret.commonError = "Technischer Fehler: Die Anfrage wird serverseitig nicht unterstützt.";
				ret.commonErrorDetails = res.data.message.en;
				break;

			case ApiErrorEnum.BdrParsingError:
			case ApiErrorEnum.BdrRejected:
				ret.commonError = "Technischer Fehler: Die Anfrage wird mit den angegebenen Parameter nicht akzeptiert.";
				ret.commonErrorDetails = res.data.message.en;
				break;

			case ApiErrorEnum.BdrPasswordError:
				ret.commonError = "Sign-Me Fehler: Die Passwortanforderungen wurden nicht erfüllt.";
				ret.commonErrorDetails = res.data.message.en;
				break;

			case ApiErrorEnum.UsernameAlreadyTaken:
				ret.commonError = "Die gewählte E-Mail-Adresse ist bereits vergeben.";
				ret.commonErrorDetails = res.data.message.en;
				break;

			case ApiErrorEnum.UsernameUnknown:
				ret.commonError = "Die gewählte E-Mail-Adresse ist nicht bekannt.";
				ret.commonErrorDetails = res.data.message.en;
				break;

			case ApiErrorEnum.TokenUnknown:
				ret.commonError = "Ihr Link in nicht gültig (TOKEN_UNKNOWN).";
				ret.commonErrorDetails = res.data.message.en;
				break;

			case ApiErrorEnum.TokenInvalid:
				ret.commonError = "Ihr Link in nicht gültig (TOKEN_INVALID).";
				ret.commonErrorDetails = res.data.message.en;
				break;

			case ApiErrorEnum.PointsUnavailable:
				ret.commonError =
					"Ihre verbleibenden digiSeal-Punkte reichen für die gewünschte Aktion nicht aus. " +
					"Für den Erwerb weiterer digiSeal-Punkte wenden Sie sich bitte an sales@secrypt.de oder telefonisch an +49 30 7565978-0.";
				ret.commonErrorDetails = res.data.message.en;
				break;
			
			case ApiErrorEnum.InvalidPAK:
				ret.commonError = "Bitte überprüfen Sie, dass der eingetragene Punkte-Zugangsschlüssel korrekt geschrieben ist.";
				break;
			
			case ApiErrorEnum.BdrSmsPinMissing:
				ret.showPinFields = true;
				break;
				
			case ApiErrorEnum.BdrSmsPinNotAllowed:
				ret.commonError = 
					"Sie haben eine Mobilfunknummer angegeben und eine SMS-Pin gesetzt. " + 
					"Bitte entfernen Sie die SMS-Pin oder verwenden eine Festnetznummer.";
				ret.commonErrorDetails = res.data.message.en;
				ret.showPinFields = true;
				break;

			default:
				if (res.data.status_code === 404) {
					ret.commonError = "Technischer Fehler: Ihr Link in nicht gültig (REQUEST_INVALID).";
					if (res.data.message && typeof res.data.message == 'string')
						ret.commonErrorDetails = res.data.message;
				}
				else if (res.data.message) {
					if (typeof res.data.message == 'string')
						ret.commonError = res.data.message;
					else if (res.data.message.de)
						ret.commonError = res.data.message.de;
					else if (res.data.message.en)
						ret.commonError = res.data.message.en;
				}
				break;
		}

		return ret;
	};

	var handleError = function(axiosError) {

		var error = axiosError;
		var ret = {
			commonError: " ",
			commonErrorDetails: undefined
		};

		if (error.code === 'ECONNABORTED') {
			ret.commonError = "Timeout: Keine Verbindung zum Server oder keine Antwort vom Server. Probieren Sie es noch einmal.";
			return ret;
		}

		ret.commonError = "Technischer Fehler: Es ist ein unbekannter Fehler aufgetreten.";
		ret.commonErrorDetails = error.message;

		return ret;
	};

	return {
		ok: handleOk,
		error: handleError,
	};
})();

export default AxiosResultHandler;