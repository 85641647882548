import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import _ from 'lodash';

/**
 * Import all constants as an object.
 */
import Common from '../../constants/common';
import sha256 from '../../constants/sha256';

/**
 * Import all apiAction and crudAction as an object.
 */
import * as apiAction from '../../actions/apiAction';
import * as crudAction from '../../actions/crudAction';
import * as flashMessage  from '../../actions/flashMessage';

// Import custom components
import FlashMessage from '../common/flash/message.component';
import {Field, reduxForm} from "redux-form";
import renderText from "../common/form/renderText";
import axios from "axios";
import DigiSeal from '../common/digiSeal.component.jsx';
import AppConstant from "../../constants/app";

var Slider = (props) => {
	return (
		<div className="slider">
			<input id={"nsl-"+props.id} type="checkbox" value="1" className={props.name} />
			<label htmlFor={"nsl-"+props.id}><span className="round"></span></label>
		</div>
	);
}

class EmailnoticesList extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.onHandleSubmit = this.onHandleSubmit.bind(this);
    }

    componentWillMount() {
        this.props.actions.removeFlashMessage();
    }

    componentWillUnmount() {
        this.props.actions.clearList(Common.NOTIFICATIONS);
        this.props.actions.removeFlashMessage();
        this.props.actions.apiClearState();
    }

    componentDidMount() {
        document.title = "E-Mail-Meldungen – digiSeal Portal by secrypt GmbH"

        // load data
        axios.post(AppConstant.API_URL + 'notifications', {})
            .then(res => {
                var enable20PercentValue = 0;
                var enable2Month = 0;
                $('input[name="notificationmailaddress"]').val(res.data.data.mailAddress);
                $('input.statisticMailType[value="'+res.data.data.statisticMailType+'"]').attr('checked', true);
                if(res.data.data.enable20Percent){
                    enable20PercentValue = 1;
                }
                $('input.enable20Percent[value="'+enable20PercentValue+'"]').attr('checked', true);
                if(res.data.data.enable2Month){
                    enable2Month = 1;
                }
                $('input.enable2Month[value="'+enable2Month+'"]').attr('checked', true);
            });

        //define listeners
        $('.enable20Percent, .enable2Month').off('change').on('change', function(e){
            e.preventDefault();
            var data = {};
            data.enable20Percent = $('.enable20Percent').is(':checked');
            data.enable2Month = $('.enable2Month').is(':checked');

            axios.post(AppConstant.API_URL + 'notification-email-set-warnings', {data})
                .then(res => {
                    var ok = res && res.data && res.data.code == 'ok';
                    $.toast({
                        heading: 'Warn-Meldungen',
                        text: ok ?
                            'Die Einstellungen für die Warn-Meldungen per E-Mail wurden erfolgreich gespeichert.' :
                            'Die Einstellungen für die Warn-Meldungen per E-Mail wurden nicht erfolgreich gespeichert.',
                        showHideTransition: 'slide',
                        position: 'top-right',
                        icon: 'success',
                        allowToastClose: true,
                        hideAfter: ok ? 3000 : 10000,
                        bgColor: ok ? '#6cbf00' : '#c01818'
                    })
                });
        })

        $('.statisticMailType').off('change').on('change', function(e){
            e.preventDefault();
            var data = {};
            data.mailType = $(this).val();

            axios.post(AppConstant.API_URL + 'notification-email-set-mailtype', {data})
                .then(res => {
                    var ok = res && res.data && res.data.code == 'ok';
                    $.toast({
                        heading: 'Verbrauchsprotokoll',
                        text: ok ?
                            'Die Einstellungen für den Versand wurden erfolgreich gespeichert.' :
                            'Die Einstellungen für den Versand wurden nicht erfolgreich gespeichert.',
                        showHideTransition: 'slide',
                        position: 'top-right',
                        icon: 'success',
                        allowToastClose: true,
                        hideAfter: ok ? 3000 : 10000,
                        bgColor: ok ? '#6cbf00' : '#c01818'
                    })
                });
        })

		$('.send-usage-protocol').off('click').on('click', function(e){
            e.preventDefault();
            $.confirm({
                title: 'Verbrauchsprotokoll senden',
                content: 'Möchten Sie das Verbrauchsprotokoll jetzt versenden?',
                buttons: {
                    confirm: {
                        text : 'Protokoll jetzt senden',
                        btnClass: 'modal-editor-btn btn-primary btn',
                        action: function () {
                            axios.post(AppConstant.API_URL + 'notification-email-send', {})
                                .then(res => {
									var ok = res && res.data && res.data.code == 'ok';
									$.toast({
										heading: 'Protokoll-Versand',
										text: ok ?
											'Das Verbrauchsprotokoll wurde versandt.' :
											'Das Verbrauchsprotokoll wurde nicht versandt.',
										showHideTransition: 'slide',
										position: 'top-right',
										icon: ok ? 'success' : 'error',
										allowToastClose: true,
										hideAfter: ok ? 3000 : 10000,
										bgColor: ok ? '#6cbf00' : '#c01818'
									})
                                });
                        }
                    },
                    cancel: {
                        text : 'Abbrechen',
                        btnClass : 'cancel-btn',
                        action: function () {}
                    },
                }
            });
        })

        $('input[name="notificationmailaddress"]').off('click').on('click', function(){
            $('.notifications-container .edit-notification-email').click();
        });

		$('.notifications-container .edit-notification-email').off('click').on('click', function (e) {

            var oldmail = $('input[name="notificationmailaddress"]').val();
            setTimeout(function() {
                const togglePasswordConfirm = document.querySelector('#togglePasswordConfirm');
                const passwordConfirm = document.getElementsByName("password-value");

                togglePasswordConfirm.addEventListener('click', function (e) {
                    const type = passwordConfirm[0].getAttribute('type') === 'password' ? 'text' : 'password';
                    passwordConfirm[0].setAttribute('type', type);
                    this.classList.toggle('dash');
                });
            }, 1000);
            $.confirm({
                title: false,
                content: '<div class="modal-editor">'+
                            '<label>Alte Empfangs E-Mail:</label><div class="old-entry">'+oldmail+'</div>'+
                            '<div class="data-container"><label>Neue Empfangs E-Mail:</label><input type="text" name="new-email-value" class="new-email-value form-control" /><span class="input-info-output"></span></div>' +
                            '<div class="data-container"><label>Mit Passwort bestätigen:</label><input type="password" name="password-value" class="password-value form-control" /><span class="input-info-output"></span><i class="eye togglePasswordConfirm positioned" id="togglePasswordConfirm"></i></div>' +
                          '</div>',
                buttons: {
                    confirm: {
                        text : 'Änderungen übernehmen',
                        btnClass: 'modal-editor-btn btn-primary btn',
                        action: function () {
                            var data = {};
                            data.newMail = $('.new-email-value').val();
                            data.password = $('.password-value').val();
                            var pwHash = $('.system-user-name').data('pw');
                            var pwInputHash = sha256(data.password).toUpperCase();

                            if (!data.newMail) {
                                $('.new-email-value').next('.input-info-output').addClass('error').html('Bitte geben Sie die neue E-Mail ein.');
                                return false;
                            }
                            if (!data.password) {
                                $('.password-value').next('.input-info-output').addClass('error').html('Bitte geben Sie das aktuelle Passwort ein.');
                                return false;
                            }
                            if (pwInputHash !== pwHash) {
                                $('.password-value').next('.input-info-output').addClass('error').html('Das aktuelle Passwort ist falsch.');
                                return false;
                            }

                            axios.post(AppConstant.API_URL + 'notification-email-update', {data})
                                .then(res => {
                                    $.toast({
                                        heading: 'Änderungen gespeichert',
                                        text: 'Ihre Änderungen wurden erfolgreich gespeichert. Bitte prüfen Sie Ihren E-Mail Posteingang.',
                                        showHideTransition: 'slide',
                                        position: 'top-right',
                                        icon: 'success',
                                        allowToastClose: true,
                                        hideAfter: 3000,
                                        bgColor : '#6cbf00'
                                    })
                                });
                        }
                    },
                    cancel: {
                        text : 'Abbrechen',
                        btnClass : 'cancel-btn',
                        action: function () {}
                    },
                }
            });
            e.preventDefault();
        })
    }

    // FORM Handling
    onHandleSubmit(formProps) {
        this.props.actions.submitForm(Common.NOTIFICATIONS, formProps, this.props.params.id);
    }

    handleChange(event) {
        var key = event.target.name;
        var value = event.target.value;
        this.props.actions.updateSelectedItem(Common.NOTIFICATIONS, key, value);
    }

    render() {

        let message = this.props.message;

        return (
                <div className="col-12 notifications-container">
                    <p>
                        Sie erhalten in regelmäßigen Zeitintervallen <DigiSeal /> Punkte-Verbrauchsprotokolle sowie ereignisbezogene Warn-Meldungen per E-Mail.
                    </p>
                    <FlashMessage message={message} />

                    <div className="col-12">
                        <div className="form-horizontal">
                            <label className="col-xs-12 control-label"><img src="./images/icons/mail_info.png" alt="" className="ticket-icon" />Empfangs-E-Mail</label>
                            <div className="col-xs-8">
                                <input type="text" name="notificationmailaddress" className="form-control" value={this.props.selectedItem.account.username} />
                            </div>
                            <div className="col-xs-4 button">
                                <button type="submit" className="btn btn-primary edit-notification-email">Bearbeiten</button>
                            </div>
                        </div>
                    </div>

                    <h4 className="subheadline padding-top-20px clear-both">Punkte-Verbrauchsprotokolle per E-Mail:</h4>
                    <div>
                        <label className="radiocontainer">Inaktiv
                            <input type="radio" name="statisticMailType" className="statisticMailType" value="DISABLED" />
                                <span className="checkmark"></span>
                        </label>
                        <label className="radiocontainer">Täglich
                            <input type="radio" name="statisticMailType" className="statisticMailType" value="DAILY" />
                                <span className="checkmark"></span>
                        </label>
                        <label className="radiocontainer">Wöchentlich
                            <input type="radio" name="statisticMailType" className="statisticMailType" value="WEEKLY" />
                                <span className="checkmark"></span>
                        </label>
                        <label className="radiocontainer">Monatlich
                            <input type="radio" name="statisticMailType" className="statisticMailType" value="MONTHLY" />
                                <span className="checkmark"></span>
                        </label>
                    </div>
                    <div className="contingent-add padding-top-20px button">
                        <div className="col-12 ">
                            <button type="submit" className="btn btn-primary send-usage-protocol">Verbrauchsprotokoll jetzt senden</button>
                        </div>
                    </div>
                    <br/>
                    <br/>

                    <h4 className="subheadline">Warn-Meldungen per E-Mail:</h4>

                    <ul className="notification-setup-list">
                        <li className="left">Zwei Monate vor Ablaufdatum Ihrer verbleibenden <DigiSeal /> Punkte</li>
                        <li className="right">
							<Slider id="1" name="enable2Month" />
                        </li>

                        <li className="left">Ein Monat vor Ablaufdatum Ihrer verbleibenden Punkte</li>
                        <li className="right">
                            <strong className="active">immer aktiv</strong>
                        </li>

                        <li className="left">20 % Ihrer Punkte sind noch verfügbar (Verbleibende Punkte)</li>
                        <li className="right">
							<Slider id="2" name="enable20Percent" />
                        </li>

                        <li className="left">10 % Ihrer Punkte sind noch verfügbar (Verbleibende Punkte)</li>
                        <li className="right">
                            <strong className="active">immer aktiv</strong>
                        </li>
                    </ul>
                </div>
        );
    }
}

EmailnoticesList.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
	data: PropTypes.array,
	columns: PropTypes.array,
	options: PropTypes.object,
};


/**
 * Map the state to props.
 */
function mapStateToProps(state) {
    return {
        notifications: state.crud.notifications,
        apiState: state.api,
        message: state.flash.message,
        selectedItem: state.crud.selectedItem,
        initialValues: state.crud.selectedItem.notifications
    }
}



/**
 * Map the actions to props.
 */
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(_.assign({}, crudAction, apiAction, flashMessage), dispatch)
    }
}

/**
 * Connect the component to the Redux store.
 */
export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'ContingentForm', // ←A Unique identifier for this form
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
})(EmailnoticesList))
