import React, { Component } from 'react';

import Schnellanleitung from '../Schnellanleitung.component.jsx';
import DigiSeal from '../digiSeal.component.jsx';

class Footer extends Component {
    constructor(props) {
        super(props);

		var consent = false;
		var l = window.localStorage;
		if (l) {
			var item = l.getItem('de.secrypt.regid.cookieconsent');
			if (item === "true")
				consent = true;
		}

		this.state = {
			cookieConsent: consent,
		};
	}

	componentDidMount() {
		$('#menu_content_small').find('.active').removeClass('active');

		// console.log("cookie consent: " + this.state.cookieConsent);
	}

	handleCookieConsentBind()
	{
		return () => {
			window.localStorage.setItem('de.secrypt.regid.cookieconsent', 'true');
			this.setState({cookieConsent:true});
		}
	}

	render(){
		var version = PORTAL_VERSION;
        return (
            <footer>
				<div className="legal">
					<div className='container'>
						<div className="itemcontainer remainder low">
							<div>&copy; 2002-2024 <a href="https://www.secrypt.de" target='_blank'>secrypt GmbH</a> | {version}</div>
							<div className='right'>
								<a className="arrow" href="https://www.secrypt.de/impressum/" target="_blank">Impressum</a> | <a className="arrow" href="https://www.secrypt.de/datenschutzerklaerung/" target="_blank">Datenschutzerklärung</a>
							</div>
						</div>
					</div>
				</div>

				<div className="main">
					<div className="container">
						<div className="itemcontainer">
							<div className="remainder">
								<h1>Kontakt</h1>
								<address>
									<strong>secrypt GmbH</strong><br />
									Hohenzollerndamm 183<br />
									10713 Berlin<br />
									Deutschland
								</address>
								<p><a href="https://www.secrypt.de/kontakt/" className="arrow" target='_blank'>Kontakt &amp; Anfrage</a></p>
								<p>
									<span className="fontel">Telefon</span> +49 30 756 59 78-0<br />
									<span className="fontel">Telefax</span> +49 30 756 59 78-18
								</p>
								<p><a className="arrow" href="mailto:mail@secrypt.de">mail@secrypt.de</a></p>
							</div>
							<div className="remainder">
								<h1>Support</h1>
								<p>
									<strong>Hotline</strong> +49 900 173 27 97<br />
									(1,99 €/Min. aus dem deutschen Festnetz)
								</p>
								<p><a href="https://www.secrypt.de/produkte/support/" target='_blank' className="arrow">Support &amp; Service</a></p>
								<p><a href="https://www.secrypt.de/produkte/support/#signmefaqs" target='_blank' className="arrow">FAQs</a></p>
								{this.props.type != 'signertoken' && <p className='arrow'>
									<Schnellanleitung className="top" dsOffice>
										Anleitung:&nbsp;
										<span className="normal">
											Signatursoftware digiSeal office (pro) mit sign-me Fernsignatur einrichten (PDF)
										</span>
									</Schnellanleitung>
								</p>}
								{this.props.type != 'signertoken' && <p className='arrow'>
									<Schnellanleitung className="top">
										Anleitung:&nbsp;
										<span className="normal">
											Registrieren und Identifizieren für sign-me Fernsignatur (PDF)
										</span>
									</Schnellanleitung>
								</p>}
								<p className='arrow'>
									<a className="top" href="https://www.secrypt.de/loesungen/fernsignatur-handy-signatur/" target="_blank">
										<span>Was ist das <DigiSeal /> Konto und<br/>
										wie funktionieren <DigiSeal /> Punkte?</span>
									</a>
								</p>

							</div>
							<div className="fixed">
								<h1>Partner</h1>
								<img className="signme" src='./images/icons/signme_white.png'></img>
								<img className="dtrust" src='./images/icons/DTRUST_white.png'></img>
								<img className="bdr"    src='./images/icons/BDR.png'></img>
							</div>
						</div>
					</div>
				</div>

				<div className="social">
					<a href="https://www.linkedin.com/company/3494835/"  target='_blank'><img src="https://www.secrypt.de/src/images/Icon-LinkedIn-white.png" /></a>
					<a href="https://www.xing.com/companies/secryptgmbh" target='_blank'><img src="./images/button/xing-white.png"     /></a>
					<a href="https://twitter.com/secrypt_GmbH"           target='_blank'><img src="./images/button/twitter-white.png"  /></a>
				</div>

				{!this.state.cookieConsent &&
					<div className="cookie-consent">
						<p>
							Durch die Nutzung der Webseite stimmen Sie zu, dass diese Webseite Cookies
							- auch von Diensten Dritter - für Analysen und die Optimierung des  Webseiten-Erlebnisses verwendet.
							Bitte beachten Sie unsere <a href="https://www.secrypt.de/datenschutzerklaerung/" target="_blank">Datenschutzerklärung</a>.
							<div className="button">
								<button type="button" onClick={this.handleCookieConsentBind()}>Ich habe verstanden!</button>
							</div>
						</p>
					</div>
				}
			</footer>
        );
    }
}

export default Footer
